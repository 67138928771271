import { Component, inject, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage, Location } from '@angular/common';
import { FrontpageAlertData } from '@mwe/models';
import { FeatureFrontpageModule } from '@mwe/features/frontpage';
import { FooterComponent } from '@ikp/layout/footer/footer.component';
import { IkpStaticContentService } from '@ikp/service/static-content/ikp-static-content.service';
import { fullRoutePrefix, ROUTE_LOGOUT_SUCCESS } from '@ikp/app.constants';
import { HeaderLogoComponent } from '@shared/layout/header/header-logo/header-logo.component';
import { IkpAuthService } from '@ikp/service/auth/ikp-auth.service';

@Component({
  selector: 'app-ikp-frontpage',
  standalone: true,
  imports: [CommonModule, FeatureFrontpageModule, HeaderLogoComponent, FooterComponent, NgOptimizedImage],
  templateUrl: './frontpage.component.html',
  styleUrl: './frontpage.component.scss',
})
export class FrontpageComponent implements OnInit {
  authService = inject(IkpAuthService);
  staticContent = inject(IkpStaticContentService);
  location = inject(Location);
  showAuthServerAlert = false;
  authServerAlertData: FrontpageAlertData;
  showTemporaryAlert = this.staticContent.showTemporaryInfoAlert;
  temporaryAlert = this.staticContent.temporaryInfoAlert;
  isLoaded = false;

  ngOnInit(): void {
    if (window.location.hash.indexOf('logout=success') !== -1) {
      this.authServerAlertData = {
        type: 'success',
        title: 'global.home.logoutSuccess.title',
        message: 'global.home.logoutSuccess.message',
      };
      this.showAuthServerAlert = true;
      this.deleteHashFromBaseUrl();
    }
    this.isLoaded = true;
  }

  private deleteHashFromBaseUrl() {
    const newUrl = window.location.href.replace(fullRoutePrefix(ROUTE_LOGOUT_SUCCESS), '');
    this.location.replaceState(newUrl);
  }

  login() {
    this.authService.login();
  }

  register() {
    this.authService.register();
  }
}
