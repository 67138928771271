import { Injectable } from '@angular/core';
import { environment } from '@ikp/environments/environment';
import { Environment } from '@ikp/environments/model/environment.model';

@Injectable({
  providedIn: 'root',
})
export class IkpEnvironmentService {
  private environment: Environment;

  constructor() {
    this.environment = environment;
  }

  getApiUrl(): string {
    return this.environment.apiUrl;
  }

  getMaintenanceRefreshIntervall(): number {
    return 5000;
  }

  getLoggingAPIOBaseURL(): string {
    return '';
  }

  getBaseUrl(): string {
    return this.environment.baseUrl;
  }

  getKeycloakUrl(): string {
    return this.environment.keycloakUrl;
  }

  getIsCookieConsentEnabled(): boolean {
    return this.environment.isCookieConsentEnabled;
  }

  getPiwikTagPropertyId(): string {
    return this.environment.piwikTagPropertyId;
  }

  getCookieConsentDataUrl(): string {
    return this.environment.cookieConsentDataUrl;
  }

  getStaticContentUrl() {
    return this.environment.staticContentUrl;
  }

  getMazePropertyId() {
    return this.environment.mazePropertyId;
  }
}
