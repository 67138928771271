import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { ApiLoadingService } from '../service/loading/api-loading.service';
import { environment } from '../environments/environment';

export const apiLoadingInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(ApiLoadingService);
  if (req.url.startsWith(environment.apiUrl)) {
    if (req.url.endsWith('pdf')) {
      return next(req);
    }
    loadingService.showLoader();
    return next(req).pipe(finalize(() => loadingService.hideLoader()));
  }
  return next(req);
};
