import { Component, OnInit } from '@angular/core';
import { UiComponentsModule } from '@mwe/ui/components';
import { RouterModule } from '@angular/router';
import { homeRoutePrefix, ROUTE_FAQ, ROUTE_INVOICES, ROUTE_METER_POINTS, ROUTE_PROFILE_DATA, ROUTE_STARTPAGE } from '@ikp/app.constants';

@Component({
  selector: 'app-ikp-footer',
  standalone: true,
  imports: [RouterModule, UiComponentsModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
  protected readonly homeRoutePrefix = homeRoutePrefix;
  protected readonly ROUTE_METER_POINTS = ROUTE_METER_POINTS;
  protected readonly ROUTE_INVOICES = ROUTE_INVOICES;
  protected readonly ROUTE_PROFILE_DATA = ROUTE_PROFILE_DATA;
  protected readonly ROUTE_FAQ = ROUTE_FAQ;
  protected readonly ROUTE_STARTPAGE = ROUTE_STARTPAGE;

  currentYear: number;
  links = {
    defaultRoute: `/`,
  };

  ngOnInit(): void {
    this.setCurrentYear();
  }

  setCurrentYear(): void {
    this.currentYear = new Date().getFullYear();
  }
}
