export const ROUTE_HOME = 'home';
export const ROUTE_STARTPAGE = '/home';
export const ROUTE_AUTH_TEST = 'auth-test';
export const ROUTE_GRID_TEST = 'grid';
export const ROUTE_METER_POINTS = 'meter-points';
export const ROUTE_INVOICES = 'invoices';
export const ROUTE_DSGVO = 'dsgvo';
export const ROUTE_FAQ = 'faq';
export const ROUTE_LOGOUT_SUCCESS = '#logout=success';
export const ROUTE_COMPANY_DATA = 'company-data';
export const ROUTE_PROFILE_DATA = 'profile-data';
export const ROUTE_REGISTRATION = 'registration';
export const ROUTE_VERFICATION = 'verification';

// API
export const API_ROUTE_ZAEHLPUNKTE = 'zaehlpunkte';
export const API_ROUTE_INVOICES = 'rechnung';
export const API_ROUTE_COMPANY = 'unternehmen';

export const API_ROUTE_USER = 'user';
export const API_ROUTE_BERATER_OF_USER = 'user/berater';

// Utility function for relative paths
export function routePrefix(route: string) {
  return '/' + route;
}

// Utility function for full URL paths
export function fullRoutePrefix(route: string) {
  // check if route already starts with /
  if (route.startsWith('/')) {
    return window.location.origin + route;
  }

  return window.location.origin + '/' + route;
}

export function homeRoutePrefix(route: string) {
  return '/' + ROUTE_HOME + routePrefix(route);
}

export function getApiUrl(apiUrl: string, route: string) {
  return `${apiUrl}/v1/ikp/auth/${route}`;
}
