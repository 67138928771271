import { Route, RouterModule } from '@angular/router';
import { FrontpageComponent } from './pages/frontpage/frontpage.component';
import { NgModule } from '@angular/core';
import { ROUTE_HOME, ROUTE_LOGOUT_SUCCESS, ROUTE_REGISTRATION } from './app.constants';
import { authGuard } from '@shared/auth/guard/auth.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    component: FrontpageComponent,
    children: [],
  },
  {
    path: ROUTE_LOGOUT_SUCCESS,
    component: FrontpageComponent,
  },
  {
    path: ROUTE_HOME,
    loadChildren: () => import('./pages/main/main.routes').then(mod => mod.MAIN_ROUTES),
    canActivate: [authGuard],
  },
  {
    path: ROUTE_REGISTRATION,
    data: {
      hideHeader: true,
      hideFooter: true,
      toggles: [],
      pageTitle: 'Registration',
    },
    loadChildren: () => import('./pages/registration/registration.module').then(mod => mod.RegistrationModule),
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
